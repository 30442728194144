class Geocoder {
  async statusIsIgnorable(status) {
    const { GeocoderStatus } = await google.maps.importLibrary("geocoding");

    return [
      GeocoderStatus.INVALID_REQUEST,
      GeocoderStatus.ZERO_RESULTS,
    ].includes(status)
  }

  async fromAddress(address, bounds) {
    const { Geocoder, GeocoderStatus } = await google.maps.importLibrary("geocoding");
    const geocoder = new Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({ address, bounds }, (results, status) => {
        if (status === GeocoderStatus.OK) {
          const location = results[0].geometry.location
          resolve({ lat: location.lat(), lng: location.lng() })
        } else {
          reject({ status, results })
        }
      })
    })
  }

  async fromCenter(center) {
    const { Geocoder, GeocoderStatus } = await google.maps.importLibrary("geocoding");
    const geocoder = new Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: center }, (results, status) => {
        if (status === GeocoderStatus.OK) {
          resolve(results[0])
        } else {
          reject({ status, results })
        }
      })
    })
  }
}

export default Geocoder
