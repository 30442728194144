import { Component } from "react"
import PropTypes from "prop-types"
import XIcon from "shared/components/icons/x_icon"
import classnames from "classnames"

export default class Modal extends Component {
  constructor() {
    super()
    this.inNewConnectUI = document.body.classList?.contains?.("new-connect-ui")
    this.maxWidthParent = document.querySelector("main.container.web")
    this.originalMaxWidth =
      this.maxWidthParent && window.getComputedStyle?.(this.maxWidthParent)?.maxWidth
  }
  // If opening a fullpage modal, this will hide the scrolling underneath it
  hideScrollBarAtHostPage = () => {
    // "Save" the position of the scroll in the host page befote hiding it
    document.body.style.top = `-${window.scrollY}px`

    this.originalBodyPosition = document.body.style.position
    document.body.style.position = "fixed"
  }

  resetScrollBarAtHostPage = () => {
    const scrollY = document.body.style.top

    document.body.style.position = this.originalBodyPosition || ""
    document.body.style.top = ""
    window.scrollTo(0, parseInt(scrollY || "0") * -1) // avoid flicker
  }

  componentDidUpdate(prevProps) {
    if (this.props.show === prevProps.show) return

    if (this.props.show && this.props.onShow) {
      this.props.onShow()
    }

    if (this.props.fullscreen) {
      if (this.maxWidthParent) {
        if (this.props.show) {
          this.maxWidthParent.style.maxWidth = "100vw"
        } else {
          this.maxWidthParent.style.maxWidth = this.originalMaxWidth
        }
      }
      // Keeping this scoped only to the new connect UI
      if (!this.inNewConnectUI) {
        return
      }
      this.props.show
        ? this.hideScrollBarAtHostPage()
        : this.resetScrollBarAtHostPage()
    }
  }

  handleModalContentClick = e => {
    // prevent propagation to closeListener
    e.stopPropagation()
  }

  renderClose() {
    const {
      closeListener,
      closeText,
      moveCloseXToHeader,
      rightMargin,
    } = this.props

    const styles = classnames("modal-close", { "right-margin": rightMargin })

    return (
      <div className={styles} onClick={closeListener}>
        {closeText ||
          (moveCloseXToHeader ? <XIcon width="18" height="18" /> : <XIcon />)}
      </div>
    )
  }

  render() {
    const {
      children,
      closeListener,
      headerText,
      headerSubText,
      includeCloseX,
      moveCloseXToHeader,
      show,
      leftJustifyHeader,
      fullscreen,
      headerClass,
    } = this.props

    if (!show) {
      return null
    }

    const contentStyles = classnames("modal-content", {
      "left-justify": leftJustifyHeader,
      fullscreen,
    })

    return (
      <div className="modal">
        <div className="modal-background" onClick={closeListener}>
          <div className={contentStyles} onClick={this.handleModalContentClick}>
            {headerText && (
              <div className="header">
                <div className="header-text">
                  <h3 className={headerClass ? "" : "no-margin"}>
                    {headerText}
                  </h3>
                  {headerSubText && <p className="no-bottom-margin">{headerSubText}</p>}
                </div>
                {includeCloseX && moveCloseXToHeader && this.renderClose()}
              </div>
            )}
            <div className="section">
              {includeCloseX && !moveCloseXToHeader && this.renderClose()}
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  closeListener: PropTypes.func,
  show: PropTypes.bool,
  onShow: PropTypes.func,
  includeCloseX: PropTypes.bool,
  moveCloseXToHeader: PropTypes.bool,
  header: PropTypes.node,
  children: PropTypes.node,
  headerText: PropTypes.string,
  headerSubText: PropTypes.string,
  closeText: PropTypes.string,
  leftJustifyHeader: PropTypes.bool,
  rightMargin: PropTypes.bool,
  fullscreen: PropTypes.bool,
  headerClass: PropTypes.string,
}

Modal.defaultProps = {
  headerText: "",
  headerSubText: "",
  includeCloseX: true,
  moveCloseXToHeader: false,
  leftJustifyHeader: false,
  rightMargin: false,
  fullscreen: false,
}
