export default function toggleHelpWidgetVisibility() {
  const footer = document.querySelector(".site-footer")
  const zendeskButton = document.querySelector("#zendesk-widget-button")
  if (!footer || !zendeskButton) return

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      zendeskButton.style.display = entry.isIntersecting ? "none" : "block"
    })
  }, { threshold: 0 })

  observer.observe(footer)
}
