import { Component } from "react"
import PropTypes from "prop-types"

import Geocoder from "./geocoder"
import MapField from "./map_field"
import LocationData from "./location_data"
import SearchBar from "./search_bar"

import classNames from "classnames"

class LocationField extends Component {
  constructor(props) {
    super(props)

    const zoom =
      (props.location.zoom && parseFloat(props.location.zoom)) ||
      this.calculateZoomFromRadius(props.location.radius)

    this.state = {
      formatted_address:
        props.location.formatted_address || props.location.address,
      lat: parseFloat(props.location.lat),
      lng: parseFloat(props.location.lng),
      radius: parseFloat(props.location.radius),
      mapLoaded: false,
      zoom,
    }

    this.onAddressBarChanged = this.onAddressBarChanged.bind(this)
    this.onMapCenterChanged = this.onMapCenterChanged.bind(this)

    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)

    this.onMapIdle = this.onMapIdle.bind(this)
    this.onMapReady = this.onMapReady.bind(this)
  }

  componentDidMount() {
    this.setState({ mapLoaded: true })

    const gpsDevice = "geolocation" in navigator
    const usingDefaultLocation = this.props.location.is_default_location

    if (!gpsDevice) return

    if (!usingDefaultLocation) return

    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords
      const { LatLng } = await google.maps.importLibrary("core");

      const ll = new LatLng(latitude, longitude)

      this.onMapCenterChanged(ll, true)
      this.map().forceUpdate(ll)
    })
  }

  onMapCenterChanged(center, automated) {
    if (!automated && this.props.onChange) {
      this.props.onChange(this.location())
    }

    return new Geocoder()
      .fromCenter(center)
      .then(result => {
        this.setState({ formatted_address: result.formatted_address })
        return result
      })
      .catch(({ status, results }) => {
        if (!new Geocoder().statusIsIgnorable(status)) {
          throw results
        }
      })
  }

  onAddressBarChanged(address, automated) {
    if (this.props.onChange) {
      this.props.onChange(this.location(), automated)
    }

    const bounds = this.bounds()
    this.setState({ formatted_address: address })

    if (!address) {
      return
    }

    return new Geocoder()
      .fromAddress(address, bounds)
      .then(lat_lng => {
        this.map().forceUpdate(lat_lng)
        return lat_lng
      })
      .catch(({ status, results }) => {
        if (!new Geocoder().statusIsIgnorable(status)) {
          throw results
        }
      })
  }

  onMapReady() {
    if (this.state.formatted_address) {
      this.onAddressBarChanged(this.state.formatted_address, true)
    }
  }

  calculateZoomFromRadius(radius) {
    // this function uses the maximum map length and compares it to the diameter
    // of the circle used on the map. The while loop serves the purpose of
    // incrementing the zoom level as long as totalMapLength is greater than the
    // diameter.
    // because we want the zoom level to encompass the diameter, the zoom level
    // is actually a step behind the totalMapLength in terms of zoom-to-length
    // ratio
    let totalMapLength = 40075004.0 / 2 // this is equal to half the length of the equator in meters
    let zoomLevel = 0
    while (totalMapLength > radius * 2) {
      zoomLevel++
      totalMapLength = totalMapLength / 2
    }
    return zoomLevel
  }

  onMapIdle(map) {
    const center = map.getCenter()

    this.setState({
      lat: center.lat(),
      lng: center.lng(),
      zoom: map.getZoom(),
      radius: this.map().radius(),
    })

    this.props.onChange?.(this.location(), true)
  }

  zoomIn() {
    this.map().zoomIn()
  }

  zoomOut() {
    this.map().zoomOut()
  }

  bounds() {
    return this.map().bounds()
  }

  location() {
    const radius = (this.map() && this.map().radius()) || this.state.radius
    const zoom = (this.map() && this.map().getZoom()) || this.state.zoom

    return {
      latitude: this.state.lat,
      longitude: this.state.lng,
      formatted_address: this.state.formatted_address,
      address: this.state.formatted_address,
      description: this.state.formatted_address,
      radius,
      zoom,
    }
  }

  map() {
    return this.mapRef
  }

  render() {
    if (!this.state.mapLoaded) return <div>Loading map…</div>

    const className = classNames({
      "location-field-v2": true,
      "with-disabled-content-overlay": this.props.disabled,
    })

    return (
      <section className={className}>
        <SearchBar
          onAddressChanged={this.onAddressBarChanged}
          textAddress={this.state.formatted_address}
        />

        <MapField
          ref={r => (this.mapRef = r)}
          lat={this.state.lat}
          lng={this.state.lng}
          onCenterChanged={this.onMapCenterChanged}
          onMapIdle={this.onMapIdle}
          onMapReady={this.onMapReady}
          zoom={this.state.zoom}
          textAddress={this.state.formatted_address}
          onZoomIn={this.zoomIn}
          onZoomOut={this.zoomOut}
          mapDirection={this.props.mapDirection}
        />

        <LocationData
          fieldName={this.props.fieldName}
          locationFields={this.location()}
        />

        {this.props.disabled && <div className="disabled-content-overlay" />}
      </section>
    )
  }
}

LocationField.propTypes = {
  location: PropTypes.object,
  fieldName: PropTypes.string,
  mapDirection: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

LocationField.defaultProps = {
  location: {
    lat: 37.78578938794374,
    lng: -122.41903230000003,
    formatted_address: "923 Market St, San Francisco, CA 94103, USA",
    radius: 471.9338926304832,
    zoom: 16,
    is_default_location: true,
  },
}

export default LocationField
